.imagen-etiqueta
{
	max-width: 50px;
	max-height: 35px;
}

.hamburger--vortex .hamburger-inner {
	transition-duration: .5s;
}
.hamburger
{
	padding:6px 6px 0 6px;
}

.hamburger-box
{
	height: 17px;
	width: 30px;
}

.hamburger-box .hamburger-inner, .hamburger-box .hamburger-inner:after, .hamburger-box .hamburger-inner:before
{
	color: var(--header-text-color);
	width: 30px!important;
	height: 3px;
}

.hamburger-inner:before {
	top: -8px;
}
.hamburger-inner:after {
	bottom: -8px;
}

.hamburger-box .hamburger-inner,
.hamburger-box .hamburger-inner:after, .hamburger-box .hamburger-inner:before
{
	background-color: var(--header-text-color);
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:after, .hamburger.is-active .hamburger-inner:before
{
	background-color: var(--header-text-color);
}

.form-check-input:checked
{
	background-color: var( --link-color );
	border-color: var( --link-color );
}

/* esta va siempre */
a:active,
a:focus,
a:hover,
{
	text-decoration: none;
	color: var( --link-color );
}

@media screen
{
	body
	{
		color: var( --text-color );
		/* font-size: .9rem; */
	}
}


a, a:visited
{
	color: var(--link-color );
	text-decoration: none;
}

a:hover
{
	color: var(--link-color-hover );
}


.table
{
	color: var(--text-color);
}

.bg-primary
{
	background-color: var(--btn-primary-bg-color ) !important;
}

.btn-primary,
.btn-secondary,
.btn-success,
.btn-danger
{
	border-radius: var(--button-border-radius);
}

.btn-primary,
.btn-primary:visited,
.btn-primary:active
{
	background-color: var(--btn-primary-bg-color );
	background-image: var( --button-style );
	border: var( --btn-primary-border-color) 1px solid;
	color: var( --btn-primary-text-color);
	box-shadow:none !important;
}


.btn-primary:hover
{
	background-color: var(--btn-primary-bg-color-hover );
	background-image: var( --button_style );
	border: var( --btn-primary-border-color-hover ) 1px solid;
	color: var( --btn-primary-text-color-hover );
}

.btn-primary:active,
{
	background-color: var(--btn-primary-bg-color-hover ) !important;
	background-image: var( --button_style );
	border: var( --btn-primary-border-color-hover ) 1px solid !important;
	color: var( --btn-primary-text-color-hover ) !important;
}


.btn-secondary,
.btn-secondary:visited,
.btn-secondary:active
{
	background-color: var(--btn-secondary-bg-color );
	background-image: var( --button-style );
	border: var( --btn-secondary-border-color) 1px solid;
	color: var( --btn-secondary-text-color);
	box-shadow:none !important;
}


.btn-secondary:hover
{
	background-color: var(--btn-secondary-bg-color-hover );
	background-image: var( --button_style );
	border: var( --btn-secondary-border-color-hover ) 1px solid;
	color: var( --btn-secondary-text-color-hover );
}

.btn-secondary:active,
{
	background-color: var(--btn-secondary-bg-color-hover ) !important;
	background-image: var( --button_style );
	border: var( --btn-secondary-border-color-hover ) 1px solid !important;
	color: var( --btn-secondary-text-color-hover ) !important;
}

.card>.card-header
{
	background-color: transparent;
}
